import { useState } from 'react';
import { MaskedCpf } from '../../app/modules/auth/components/MaskedCpf';
import { InputPublico } from '../../app/modules/auth/components/InputPublic';
import Botao from '../Botao';
import { Link } from 'react-router-dom';
import UsuarioService from '../../services/UsuarioService';
import { FiEye, FiEyeOff } from 'react-icons/fi'; 

const usuarioService = new UsuarioService();

export function Login() {
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [mostrarSenha, setMostrarSenha] = useState(false); 

  const aoSubmeter = async (e) => {
    e.preventDefault();


    try {
      const retorno = await usuarioService.login({
        cpf: cpf,
        password: senha,
      });

      if (retorno.status === 200) {
        if (usuarioService.estaAutenticado() === true) {
          window.location.reload(true);
        }
      } else {
        let msg = retorno.data.message;
        if (msg === 'Unauthorized') {
          msg = 'Senha incorreta ou Usuário não autorizado';
        }
        document.getElementById('divMsgError').className =
          'mb-lg-15 alert alert-danger';
        document.getElementById('msgError').className =
          'alert-text font-weight-bold';
        document.getElementById('msgError').textContent = msg;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form
      className='form w-100'
      onSubmit={aoSubmeter}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Acessar</h1>
        <div className='text-gray-400 fw-bold fs-4'></div>
      </div>
      <div
        class='mb-10 bg-light-info p-8 rounded'
        id='divMsgError'
        style={{ textAlign: 'center' }}
      >
        <div class='text-info' id='msgError'>
          <strong>Digite seus dados de Acesso</strong>
        </div>
      </div>
      <label className='form-label fs-6 fw-bolder text-dark'>Cpf</label>
      <MaskedCpf
        placeholder='CPF'
        value={cpf}
        type='text'
        aoAlterarValor={(e) => setCpf(e.target.value)}
      />
      <br />
      <label className='form-label fs-6 fw-bolder text-dark'>Senha</label>
      
      <div className='position-relative'>
        <InputPublico
          placeholder='Senha'
          type={mostrarSenha ? 'text' : 'password'}
          value={senha}
          aoAlterarValor={(e) => setSenha(e.target.value)}
          className='form-control form-control-lg form-control-solid'
        />
        <span
          className='position-absolute end-0 top-50 translate-middle-y cursor-pointer'
          style={{padding: '15px'}}          
          onClick={() => setMostrarSenha(!mostrarSenha)}
        >
          {mostrarSenha ? <FiEyeOff /> : <FiEye />}
        </span>
      </div>
      <div>
      <Link
          to='/auth/forgot-password'
          className='link-primary fs-6 fw-bolder'
          style={{marginLeft: '5px'}}
        >
          Esqueceu a senha ?
        </Link>
      </div>
      <br></br>
      <Botao
        texto={'Acessar'}
        tipo='submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        manipularClique={() => aoSubmeter()}
      />

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Não tem conta?{''}
        <p className='link-primary'>Procure um administrador para realizar seu cadastro!</p>
      </div >
      <p className='text-gray-500 text-end' style={{fontSize: 10, marginTop: 20}}>Version 0.2.5</p>
    </form>
  );
}
