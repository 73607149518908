// utils/dateUtils.ts

export function dataFormat(data: any, endOfDay = false): string {
    if (!data) return '';
    const dataObj = new Date(data);
    if (isNaN(dataObj.getTime())) return '';
    if (endOfDay) {
      dataObj.setUTCHours(23, 59, 59, 999);
    } else {
      dataObj.setUTCHours(0, 0, 0, 0);
    }
    const dataFormatada = dataObj.toISOString();
    return dataFormatada;
  }
  