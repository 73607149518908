import React from 'react'
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {AcidenteExport} from './types'

interface Props {
  dadosExport: AcidenteExport[]
}

export function ExportarDados({dadosExport}: Props) {
  console.log(dadosExport)
  const exportarParaCSV = () => {
    const data = new Date().toISOString().slice(0, 10)
    const filename = `dados_${data}.csv`

    // Cabeçalhos das colunas
    const headers = [
      'Rua 1', 'Rua 2', 'Bairro', 'Cidade', 'UF', 'Latitude', 'Longitude',
      'Referência', 'Data Ocorrência', 'Hora', 'Tipo de Acidente', 'Pavimento',
      'Veículos Retirados', 'Descrição do Sinistro', 'Sugestões de Melhorias',
      'Falta de Atenção', 'Origem', 'Órgão Solicitador', 'Primeiro Atendimento',
      'Causas', 'Infrações', 'Órgãos Presentes', 'Sinalizações', 'Veículos',
      'Condutores', 'Vítimas'
    ]

    // Dados das linhas
    const rows = dadosExport.map(acidente => [
      acidente.rua1,
      acidente.rua2,
      acidente.bairro,
      acidente.cidade,
      acidente.uf,
      acidente.lat,
      acidente.longi,
      acidente.referencia || '',
      new Date(acidente.data_ocorrido).toLocaleDateString(),
      new Date(acidente.hora).toLocaleTimeString(),
      acidente.tipo_acidente,
      acidente.pavimento,
      acidente.veiculos_retirados,
      acidente.descricao_sinistro,
      acidente.sugestoes_melhorias,
      acidente.falta_atencao ? 'Sim' : 'Não',
      acidente.origem,
      acidente.orgao_solicitador.nome,
      acidente.primeiro_atendimento.nome,
      acidente.causas.map(causa => causa.tipo).join(', '),
      acidente.infracoes.map(infracao => infracao.tipo).join(', '),
      acidente.orgaos_presentes.map(orgao => orgao.nome).join(', '),
      acidente.sinalizacoes.map(sinalizacao => sinalizacao.tipo).join(', '),
      acidente.veiculos.map(veiculo => veiculo.tipo).join(', '),
      acidente.condutores.map(condutor => condutor.nome).join(', '),
      acidente.vitimas.map(vitima => vitima.nome).join(', ')
    ].map(value => `"${value}"`).join(','))

    // Criação do conteúdo CSV
    const csvContent = `data:text/csv;charset=utf-8,${[headers.join(','), ...rows].join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Button
      onClick={exportarParaCSV}
      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
      style={{width: 80, height: 50}}
    >
      Baixar  <FontAwesomeIcon icon={faDownload} />
    </Button>
  )
}
