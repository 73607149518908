import React, {useState, useRef, useEffect} from 'react'
import clsx from 'clsx'
import Localizacao from '../Localizacao'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import TipoAcidente from '../TipoAcidente'
import LineTabsInfracao from '../LineTabsInfracao'
import {ErroHTTP} from '../Error'
import Cookies from 'universal-cookie'
import Imagens from '../Imagens'

const cookies = new Cookies()
const tipoUsuario = cookies.get('tipo_acesso')

let mensagemError = cookies.get('tipo_acesso')
if (tipoUsuario === 'Agente') {
  mensagemError = 'Usuário não autorizado!'
}

const FormularioEtapas: React.FC = () => {
  const [etapaAtual, setEtapaAtual] = useState<number>(1)
  const [orgao, setOrgao] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [data, setData] = useState<string>('')
  const [hora, setHora] = useState<string>('')
  const [mensagemErro, setMensagemErro] = useState<string>('')
  const [erroHTTP, setErroHTTP] = useState<boolean>(false)
  
  const etapasAnterioresRef = useRef<any[]>([])

  async function fetchDataHoraMat() {
    try {
      const {data_ocorrido, hora, matricula} = await obterDadosAcidente()
      const dataFormatada = new Date(data_ocorrido).toLocaleDateString('pt-BR')
      const horaFormatada = new Date(hora).toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })
      setData(dataFormatada)
      setHora(horaFormatada)
      setErroHTTP(false)
    } catch (error) {
      setErroHTTP(true)
    }
  }

  useEffect(() => {
    fetchDataHoraMat()
  }, [])
  const dataFormatoInput = data.split('/').reverse().join('-')
  const horaFormatoInput = hora.slice(0, 5)

  const camposObrigatorios = useRef<any[]>([{nome: 'logradouro', valor: ''}])

  const isCamposObrigatoriosPreenchidos = (): boolean => {
    const preenchidos = camposObrigatorios.current.every((campo) => campo.valor.trim() !== '')
    return preenchidos
  }

  const avancarEtapa = () => {

    if (isCamposObrigatoriosPreenchidos()) {
      setMensagemErro('Por favor, preencha todos os campos obrigatórios.')
    } else {
      etapasAnterioresRef.current[etapaAtual - 1] = {}
      setEtapaAtual(etapaAtual + 1)
      setMensagemErro('')
    }
  }

  const retrocederEtapa = () => {
    if (etapaAtual > 1) {
      const etapaAnterior = etapaAtual - 1
      setEtapaAtual(etapaAnterior)
      setMensagemErro('')
    }
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (etapaAtual === 1 && (orgao.trim() === '' || email.trim() === '')) {
      setMensagemErro('Por favor, preencha todos os campos obrigatórios.')
    }
  }

  return (
    <div className='card mb-10'>
      <div className='card-body'>
        {erroHTTP ? (
          <ErroHTTP
            mensagem='Oops... Algo deu errado na requisição.'
            msgError={mensagemError}
            imagemWidth='30%'
          />
        ) : (
          <>
            <h1 className='text-center'>RAT - REGISTRO DE ACIDENTES DE TRÂNSITO</h1>
            <form onSubmit={handleSubmit} className='form'>
              <div className='tab-content pt-3'>
                <div className={clsx('tab-pane', {active: etapaAtual === 1})}>
                  <h4 className='text-center my-10'>LOCALIZAÇÃO E ÓRGÃOS</h4>

                  <div className='d-flex justify-content-center align-items-center mb-10'>
                    <div className='input-group'>
                      <input
                        type='date'
                        className='form-control border-0 font-weight-bold'
                        placeholder='Input 1'
                        value={dataFormatoInput || 'NÃO INFORMADO'}
                        onChange={(e) => setData(e.target.value)}
                        style={{backgroundColor: '#e9ecef', color: 'black'}}
                      />
                    </div>
                    <div className='input-group mx-2'>
                      <input
                        type='time'
                        className='form-control border-0 font-weight-bold'
                        placeholder='Input 2'
                        value={horaFormatoInput || 'NÃO INFORMADO'}
                        onChange={(e) => setHora(e.target.value)}
                        style={{backgroundColor: '#e9ecef', color: 'black'}}
                      />
                    </div>
                    <div className='input-group'>
                      <label
                        className='form-control col-lg-3 col-form-label text-center border-0 font-weight-bold'
                        placeholder='Input 3'
                        style={{backgroundColor: '#e9ecef', color: 'black'}}
                      >
                        701128
                      </label>
                    </div>
                  </div>

                  <Localizacao />
                </div>
                <div className={clsx('tab-pane', {active: etapaAtual === 2})}>
                  <h4 className='text-center my-10'>IMAGENS</h4>
                  <div className='d-flex justify-content-center'>
                    <Imagens />
                  </div>
                </div>
                <div className={clsx('tab-pane', {active: etapaAtual === 3})}>
                  <LineTabsInfracao />
                </div>
                <div className={clsx('tab-pane', {active: etapaAtual === 4})}>
                  <TipoAcidente />
                </div>
              </div>
              <div className='card-footer py-6' style={{marginTop: '10em'}}>
                <div className='row'>
                  <div className='col-lg-3'></div>

                  <div
                    className='col-lg-6 d-flex justify-content-center align-items-center'
                    style={{margin: '10px'}}
                  >
                    {etapaAtual > 1 && (
                      <button
                        type='button'
                        onClick={retrocederEtapa}
                        className='btn btn-active-light btn-color-muted me-2'
                        style={{border: '1px solid #bfbfbf'}}
                      >
                        Voltar
                      </button>
                    )}
                    {etapaAtual < 4 && (
                      <button type='button' onClick={avancarEtapa} className='btn btn-primary'>
                        Próxima
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default FormularioEtapas
