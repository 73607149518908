import React, {useState} from 'react'
import InputFormulario from '../Input/InputFormulario'
import {MultiSelect} from '../../app/modules/auth/components/MultiSelect'
import SelectInput from '../Input/SelectInput'
import CheckboxInput from '../Input/CheckboxInput'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSearch, faTimes, faSpinner} from '@fortawesome/free-solid-svg-icons'
import {Tabela} from '../TabelaAcidentes'
import {DadosNaoEncontrados} from '../DadosNaoEncontrados'
import {ErroHTTP} from '../Error'
import {dataFormat} from '../../app/utils/dateUtils'
import {Acidente} from './types'
import UsuarioService from '../../services/UsuarioService'
import Cookies from 'universal-cookie'
import {capitalizeAllWords} from '../../app/utils/primeiraLetraMaiuscula'
import tipoAcidentes, {causasList, tipoAcidenteList, tipoVeiculoList} from './listasAcidente'
import {ExportarDados} from '../ExportarDados'
import {AcidenteExport} from '../ExportarDados/types'

const cookies = new Cookies()
const usuarioService = new UsuarioService()

export function Filtro() {
  const [dataInicial, setDataInicial] = useState<string>('')
  const [dataFinal, setDataFinal] = useState<string>('')
  const [bairro, setBairro] = useState<string>('')
  const [corredor, setCorredor] = useState<string>('')
  const [provaveiCausas, setProvaveiCausas] = useState<string[]>([])
  const [acidente, setAcidente] = useState<string[]>([])
  const [obito, setObito] = useState<boolean>()
  const [dados, setDados] = useState<Acidente[]>([])
  const [dadosExport, setDadosExports] = useState<AcidenteExport[]>([])
  const [visualizarClicado, setVisualizarClicado] = useState<boolean>(false)
  const [erroHTTP, setErroHTTP] = useState<boolean>(false)
  const [erroMessage, setErroMessage] = useState<string>('')
  const [acessoNeagdo, setAcessoNeagdo] = useState<boolean>(false)
  const [tiposVeiculos, setTiposVeiculos] = useState<string[]>([])
  const [carregando, setCarregando] = useState(false)

  const tipoUsuario = cookies.get('tipo_acesso')
  const dataInicialFormatada: string = dataFormat(dataInicial)
  const dataFinalFormatada: string = dataFormat(dataFinal, true)

  const aoVisualizar = async () => {
    try {
      setCarregando(true)
      const filtro: any = {}

      if (corredor && corredor !== null) filtro.corredores = [corredor.toUpperCase()]
      if (dataInicialFormatada && dataInicialFormatada !== null)
        filtro.data_inicial = dataInicialFormatada
      if (dataFinalFormatada && dataFinalFormatada !== null) filtro.data_final = dataFinalFormatada
      if (bairro && bairro !== null) filtro.bairros = [bairro.toUpperCase()]
      if (acidente.length > 0 && acidente !== null) filtro.tipos_acidentes = acidente
      if (provaveiCausas.length > 0 && provaveiCausas !== null) filtro.causas = provaveiCausas
      if (obito && obito !== null) filtro.obito = obito
      if (tiposVeiculos.length > 0 && tiposVeiculos !== null)
        filtro.tipos_veiculos = tiposVeiculos

      console.log(provaveiCausas)
      console.log(tiposVeiculos)

      const retorno: Acidente[] = await usuarioService.fitro(filtro)
      const retornoExport: AcidenteExport[] = await usuarioService.fitro(filtro)

      if (tipoUsuario === 'Agente') {
        setAcessoNeagdo(true)
        setErroHTTP(true)
        setErroMessage('Usuário não autorizado!')
      }

      setDados(retorno || [])
      setDadosExports(retornoExport)
      setVisualizarClicado(true)
      setCarregando(false)
    } catch (error) {
      setErroHTTP(true)
      setDados([])
      setCarregando(false)
    }
  }

 
  const onSelectCausas = (selectedList: string[], selectedItem: string) => {
    setProvaveiCausas(selectedList)
  }
  const onRemoveCausas = (selectedList: string[], removedItem: string) => {
    setProvaveiCausas(selectedList)
  }
  const handleChangeCausas = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(event.target.value)
  }

  const onSelectVeiculos = (selectedList: string[], selectedItem: string) => {
    setTiposVeiculos(selectedList)
  }
  const onRemoveVeiculos = (selectedList: string[], removedItem: string) => {
    setTiposVeiculos(selectedList)
  }
  const handleChangeVeiculos = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(event.target.value)
  }
  const handleLimparTiposVeiculos = () => {
    setTiposVeiculos([]);
  };
  


  const onSelectAcidente = (selectedList: string[], selectedItem: string) => {
    setAcidente(selectedList)
  }
  const onRemoveAcidente = (selectedList: string[], removedItem: string) => {
    setAcidente(selectedList)
  }
  const handleChangeAcidente = (event: React.ChangeEvent<HTMLInputElement>) => {
    //console.log(event.target.value)
  }

  const handleLimparFiltro = () => {
    setDataInicial('')
    setDataFinal('')
    setBairro('')
    setCorredor('')
    setAcidente([])
    setObito(false)
    handleLimparTiposVeiculos()
    setProvaveiCausas([])
  }

  return (
    <div className='card mb-10'>
      <div className='card-body'>
        <h1 className='text-center'>ESTATÍSTICA</h1>
        <div className='d-flex justify-content-center align-items-center mb-10'>
          <div className='input-group'>
            <InputFormulario
              label='Data Inicial'
              type='date'
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
            <InputFormulario
              label='Data Final'
              type='date'
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
            <InputFormulario
              label='Bairro'
              type='text'
              value={bairro}
              onChange={(e) => setBairro(e.target.value)}
            />
            <InputFormulario
              label='Corredor'
              type='text'
              value={corredor}
              onChange={(e) => setCorredor(e.target.value)}
            />
            <CheckboxInput
              label='Acidente com Óbito'
              onChange={(e) => setObito(e.target.checked)}
            />
          </div>
        </div>
        <div className='form-control border-0 font-weight-bold input-group d-flex '>
          <MultiSelect
            label={'Qual o tipo de acidente?'}
            placeholder={'Veículos'}
            opcoes={tipoAcidenteList}
            onSelect={onSelectAcidente}
            onRemove={onRemoveAcidente}
            onChange={handleChangeAcidente}
          />
          <MultiSelect
            label={'Qual o tipo de veículo?'}
            placeholder={'Veículos'}
            opcoes={tipoVeiculoList}
            onSelect={onSelectVeiculos}
            onRemove={onRemoveVeiculos}
            onChange={handleChangeVeiculos}
          />
          <MultiSelect
            label={'Quais as prováveis causas?'}
            placeholder={'Causas'}
            opcoes={causasList}
            onSelect={onSelectCausas}
            onRemove={onRemoveCausas}
            onChange={handleChangeCausas}
          />
        </div>
        <div className='py-6'>
          <div className='row'>
            <div className='col-lg-3'></div>
            <div
              className='col-lg-6 d-flex justify-content-center align-items-center'
              style={{margin: '10px'}}
            >
              <button
                type='button'
                onClick={aoVisualizar}
                className='btn btn-primary'
                disabled={carregando}
              >
                {carregando ? (
                  <FontAwesomeIcon icon={faSpinner} spin className='me-2' />
                ) : (
                  <FontAwesomeIcon icon={faSearch} className='me-2' />
                )}
                Visualizar
              </button>
              <button
                type='button'
                onClick={handleLimparFiltro}
                className='btn btn-active-light btn-color-muted me-2'
                style={{border: '1px solid #bfbfbf'}}
              >
                <FontAwesomeIcon icon={faTimes} className='me-2' /> Apagar Filtro
              </button>
            </div>
          </div>
        </div>
        {erroHTTP || acessoNeagdo ? (
          <ErroHTTP
            mensagem='Oops... Algo deu errado na requisição. '
            imagemWidth='30%'
            msgError={erroMessage}
          />
        ) : (
          visualizarClicado &&
          dados.length === 0 && (
            <DadosNaoEncontrados mensagem='Oops... Dados não encontrados' imagemWidth='30%' />
          )
        )}
        {dados.length > 0 && (
          <>
            <Tabela dados={dados} />
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
              <ExportarDados dadosExport={dadosExport} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
