import {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {PageTitle} from '../../_metronic/layout/core'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import { DadosNaoEncontrados } from '../DadosNaoEncontrados'

interface Vitima {
  atendimento: string
  nome: string
  cpf: string
  idade: number
  sexo: string
  veiculo_id: string
}

interface Veiculo {
  id: string // Adicione esta linha
  cor: string
  marca: string
  modelo: string
  placa: string
  tipo: string
  veiculo_retirado: number
}

export default function Vitimas() {
  const [vitimas, setVitimas] = useState<Vitima[]>([])
  const [veiculos, setVeiculos] = useState<Veiculo[]>([])

  async function fetchDadosAcidente() {
    try {
      const {vitimas, veiculos} = await obterDadosAcidente()
      setVitimas(vitimas)
      setVeiculos(veiculos)
    } catch (error) {
      console.log('Erro ao buscar dados do acidente: ', error)
    }
  }

  useEffect(() => {
    fetchDadosAcidente()
  }, [])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Detalhamento</PageTitle>
      <strong style={{color: '#a1a5b7'}}>VÍTIMAS</strong>
      <br />
      <br />
      {vitimas.length === 0 ? (
        <DadosNaoEncontrados mensagem='Oops... Nenhuma vítima cadastrada' imagemWidth='30%'/>
      ) : (
        <div className='row row-cols-1 row-cols-lg-2 g-9'>
          {vitimas.map((vitima, index) => {
            const veiculoCorrespondente = veiculos.find(
              (veiculo) => veiculo.id === vitima.veiculo_id
            )
            return (
              <div
                key={index}
                className={`card card-bordered ${vitimas.length === 1 ? 'mx-auto' : ''}`}
              >
                <div className='card-header justify-content-end ribbon ribbon-start ribbon-clip'>
                  <div className='card-title'>
                    Vítima do Veículo com Placa: {veiculoCorrespondente?.placa}.
                  </div>
                </div>
                <div className='card-body'>
                  <Form>
                    <Row>
                      <Col>
                        <Form.Label>ATENDIMENTO</Form.Label>
                        <Form.Control
                          type='text'
                          value={vitima.atendimento || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Form.Label>NOME</Form.Label>
                        <Form.Control
                          type='text'
                          value={vitima.nome || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                      <Col>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                          type='text'
                          value={vitima.cpf || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Form.Label>IDADE</Form.Label>
                        <Form.Control
                          type='text'
                          value={vitima.idade || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                      <Col>
                        <Form.Label>SEXO</Form.Label>
                        <Form.Control
                          type='text'
                          value={vitima.sexo || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
