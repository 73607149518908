import React, { useEffect, useRef, useState } from 'react'
import InputFormulario from '../Input/InputFormulario'
import { obterDadosAcidente } from '../../app/utils/acidenteUtils'
import { Orgao } from '../Orgao'

interface Causa {
  tipo: string
}
interface Descricao {
  descricao: string
}

const TipoAcidente: React.FC = () => {
  const [tipoAcidente, setTipoAcidente] = useState<string>('')
  const [causas, setCausas] = useState<Causa[]>([])
  const [pavimento, setPavimento] = useState<string>('')
  const [sinalizacoes, setSinalizacoes] = useState<Causa[]>([])
  const [desrespeitoSinalizacao, setDesrespeitoSinalizacao] = useState<Descricao[]>([])
  const [reforcoSinalizacao, setReforcoSinalizacao] = useState<Descricao[]>([])
  const [cometimentoInfracao, setCometimentoInfracao] = useState<Descricao[]>([])
  const [descricao, setDescricao] = useState<string>('')
  const [melhorias, setMelhorias] = useState<string>('')

  async function fetchTipoAcidente() {
    const {
      tipo_acidente,
      causas,
      pavimento,
      sinalizacoes,
      desrespeito_sinalizacao,
      reforco_sinalizacao,
      cometimento_infracoes,
      descricao_sinistro,
      sugestoes_melhorias,
    } = await obterDadosAcidente()
    setTipoAcidente(tipo_acidente || 'Não informado')
    setPavimento(pavimento || 'Não informado')
    setCausas(causas || ['Não informado'])
    setSinalizacoes(sinalizacoes || ['Não informado'])
    setDesrespeitoSinalizacao(desrespeito_sinalizacao || ['Não informado'])
    setReforcoSinalizacao(reforco_sinalizacao || ['Não informado'])
    setCometimentoInfracao(cometimento_infracoes || ['Não informado'])
    setDescricao(descricao_sinistro || 'SEM DESCRIÇÃO')
    setMelhorias(sugestoes_melhorias || 'SEM SUGESTÕES')
  }
  useEffect(() => {
    fetchTipoAcidente()
  }, [])

  return (
    <div>
      <div className='row mt-4'>
        <div className='col-lg-6'>
          <h5 className='text-center'>ACIDENTES E CAUSAS </h5>
          <InputFormulario
            value={tipoAcidente || 'NÃO INFORMADO'}
            type='string'
            obrigatorio={true}
            placeholder='TIPO DE ACIDENTE'
            label='TIPO DE ACIDENTE'
            disabled={true}
            style={{ backgroundColor: '#e9ecef' }}
          />
          <div className='row mt-4'>
            <label className='col-lg-12 col-form-label'>PROVÁVEIS CAUSAS:</label>
            <div className='col-lg-9'>
              <ul
                className='form-control form-control-solid'
                style={{
                  paddingLeft: '1rem',
                  listStylePosition: 'inside',
                  margin: 0
                }}
              >
                {causas.map((causa: Causa, index: number) => (
                  <li key={index} style={{ margin: '0.5rem 0' }}>
                    {causa.tipo}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </div>
        <div className='col-lg-6'>
          <h5 className='text-center'>PAVIMENTO E SINALIZAÇÃO </h5>
          <InputFormulario
            value={pavimento || 'NÃO INFORMADO'}
            type='string'
            placeholder='PAVIMENTO'
            label='PAVIMENTO'
            disabled={true}
            style={{ backgroundColor: '#e9ecef' }}
          />
          <div className='row mt-4'>
            <label className='col-lg-12 col-form-label'>SINALIZAÇÃO </label>
            <div className='col-lg-9'>
              <ul className='form-control form-control-solid'
                style={{
                  paddingLeft: '1rem',
                  listStylePosition: 'inside',
                  margin: 0
                }}>
                {sinalizacoes.map((sinal: Causa, index: number) => (
                  <li key={index}>{sinal.tipo || 'NÃO INFORMADO'}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <br /><br /><br />
        <div className='row'>
          <label className='col-lg-12 col-form-label text-center'>OBSERVAÇÕES FINAIS</label>
          <div className='col-lg-6'>
            <div className='col-lg-12'>
              <div className='row mt-4'>
                <label className='col-lg-12 col-form-label'>
                  HOUVE DESRESPEITO À SINALIZAÇÃO?{' '}
                </label>
                {desrespeitoSinalizacao ? (
                  <div className='col-lg-9 '>
                    <ul className='form-control form-control-solid'
                    >
                      {desrespeitoSinalizacao.map((desrespeito: Descricao, index: number) => (
                        <li key={index}>{desrespeito.descricao || 'NÃO INFORMADO'}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <label className='col-lg-12 text-center' style={{ color: 'red' }}>
                    NÃO
                  </label>
                )}
                <label className='col-lg-12 col-form-label'>
                  É NECESSÁRIO REFORÇO À SINALIZAÇÃO?{' '}
                </label>
                {reforcoSinalizacao ? (
                  <div className='col-lg-9 '>
                    <ul className='form-control form-control-solid'>
                      {reforcoSinalizacao.map((reforco: Descricao, index: number) => (
                        <li key={index}>{reforco.descricao || 'NÃO INFORMADO'}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <label className='col-lg-12 text-center' style={{ color: 'red' }}>
                    NÃO
                  </label>
                )}
                <label className='col-lg-12 col-form-label'>
                  HOUVE PROVÁVEL COMETIMENTO DE INFRAÇÃO?{' '}
                </label>
                {cometimentoInfracao ? (
                  <div className='col-lg-9 '>
                    <ul className='form-control form-control-solid'
                style={{
                  paddingLeft: '1rem',
                  listStylePosition: 'inside',
                  margin: 0
                }}>
                      {cometimentoInfracao.map((infracao: Descricao, index: number) => (
                        <li key={index}>{infracao.descricao || 'NÃO INFORMADO'}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <label className='col-lg-12 text-center' style={{ color: 'red' }}>
                    NÃO
                  </label>
                )}
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='col-lg-12'>
              <div className='row mt-4'>
                <label className='col-lg-12 col-form-label'>DESCRIÇÃO SINISTRO</label>
                <div className='col-lg-9'>
                  <div className='form-control form-control-solid'>{descricao || 'NÃO INFORMADO'}</div>
                </div>
                <label className='col-lg-12 col-form-label'>SUGESTÃO DE MELHORIAS</label>
                <div className='col-lg-9'>
                  <div className='form-control form-control-solid'>{melhorias || 'NÃO INFORMADO'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TipoAcidente
