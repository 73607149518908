import React, {useEffect, useRef, useState} from 'react'
import InputFormulario from '../Input/InputFormulario'
import UsuarioService from '../../services/UsuarioService'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import {Orgao} from '../Orgao'

declare global {
  interface Window {
    google: any
    initMap: () => void
  }
}

const Localizacao: React.FC = () => {
  const [cruzamento, setCruzamento] = useState<boolean>(false)
  const [logradouro, setLogradouro] = useState<string>('')
  const [logradouro2, setLogradouro2] = useState<string>('')
  const [bairro, setBairro] = useState<string>('')
  const [cidade, setCidade] = useState<string>('')
  const [uf, setUf] = useState<string>('')
  const [pontoDeReferencia, setPontoDeReferencia] = useState<string>('')
  const [darkTheme, setDarkTheme] = useState<boolean>(false)

  const toggleTheme = () => {
    setDarkTheme(!darkTheme)
  }

  const mapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const loadGoogleMaps = () => {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCIguWNVLK--yFLqs1h9c6WSMTY2cpdPzY&callback&callback=initMap`
      script.async = true
      script.defer = true
      script.onerror = () => {
        console.error('Error loading Google Maps API.')
      }
      window.initMap = initMap
      document.body.appendChild(script)
    }

    if (!window.google) {
      loadGoogleMaps()
    } else {
      initMap()
    }
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      acidente()
    }
  }, [mapRef.current])

  const initMap = () => {
    const google = window.google
    if (!google) {
      console.error('Error loading Google Maps API.')
      return
    }

    // Initialize map here
  }

  const acidente = async () => {
    try {
      const {lat, lng, estado} = await obterDadosAcidente()

      const setEstado = (valor: string | null | undefined, setter: (value: string) => void) => {
        if (valor != null) {
          setter(valor)
        }
      }

      setEstado(estado.logradouro, setLogradouro)
      if (estado.cruzamento) {
        setCruzamento(true)
      }
      setEstado(estado.bairro, setBairro)
      setEstado(estado.cidade, setCidade)
      setEstado(estado.uf, setUf)
      setEstado(estado.pontoDeReferencia, setPontoDeReferencia || '')

      if (lat && lng) {
        const google = window.google
        if (google) {
          const map = new google.maps.Map(mapRef.current!, {
            center: {lat: parseFloat(lat), lng: parseFloat(lng)},
            zoom: 16,
          })

          const marker = new google.maps.Marker({
            position: {lat: parseFloat(lat), lng: parseFloat(lng)},
            map: map,
            title: 'Local do acidente',
          })

          const infowindow = new google.maps.InfoWindow({
            content: `<div>Latitude: ${lat}</div><div>Longitude: ${lng}</div>`,
          })

          marker.addListener('click', () => {
            infowindow.open(map, marker)
          })
        } else {
          console.error('Google Maps API not available.')
        }
      } else {
        console.error('Accident coordinates not found.')
      }
    } catch (error) {
      console.error('Error getting accident data:', error)
    }
  }

  return (
    <div>
      <div ref={mapRef} style={{width: '100%', height: '400px'}} />
      <div className='row mt-4'>
        <div className='col-lg-6'>
          <h5 className='text-center'>Localização </h5>

          <InputFormulario
            value={cruzamento ? 'SIM' : 'NÃO' || 'NÃO INFORMADO'}
            onChange={(e) => {
              setLogradouro(e.target.value)
            }}
            type='string'
            obrigatorio={true}
            placeholder='Foi cruzamento?'
            label='Foi cruzamento?'
            disabled={true}
            style={{color: cruzamento ? 'green' : 'red', backgroundColor: '#e9ecef'}}
            cruzamentoInputStyle={true}
          />

          {/* <label className='form-control form-control-solid'>
            Foi cruzamento?{' '}
            {cruzamento ? (
              <label
                className={`text-lg-end col-form-label ${darkTheme ? 'text-success' : ''}`}
                style={{marginLeft: '25%'}}
              >
                SIM
              </label>
            ) : (
              <label className={`${darkTheme ? 'text-danger' : ''}`} style={{marginLeft: '50%'}}>
                NÃO
              </label>
            )}
          </label> */}
          {/* InputFormularios de Localização */}
          <InputFormulario
            value={logradouro || 'NÃO INFORMADO'}
            onChange={(e) => {
              setLogradouro(e.target.value)
            }}
            type='string'
            obrigatorio={true}
            placeholder='Logradouro'
            label='Logradouro'
            disabled={true}
            style={{backgroundColor: '#e9ecef'}}
          />
          {cruzamento && (
            <InputFormulario
              value={logradouro2 || 'NÃO INFORMADO'}
              onChange={(e) => {
                setLogradouro(e.target.value)
              }}
              type='string'
              obrigatorio={true}
              placeholder='Logradouro 2'
              label='Logradouro 2'
              disabled={true}
            />
          )}
          <InputFormulario
            value={bairro || 'NÃO INFORMADO'}
            onChange={(e) => {
              setBairro(e.target.value)
            }}
            type='string'
            obrigatorio={true}
            placeholder='Bairro'
            label='Bairro'
            disabled={true}
            style={{backgroundColor: '#e9ecef'}}
          />
          <InputFormulario
            value={uf || 'NÃO INFORMADO'}
            onChange={(e) => {
              setUf(e.target.value)
            }}
            type='string'
            obrigatorio={true}
            placeholder='UF'
            maxLength={2}
            label='UF'
            disabled={true}
            style={{backgroundColor: '#e9ecef'}}
          />
          <InputFormulario
            value={pontoDeReferencia || 'NÃO INFORMADO'}
            onChange={(e) => {
              setPontoDeReferencia(e.target.value)
            }}
            type='string'
            obrigatorio={true}
            placeholder='Ponto de Referência'
            label='P. Referência'
            disabled={true}
            style={{backgroundColor: '#e9ecef'}}
          />
        </div>

        {/* Componente Orgao */}
        <div className='col-lg-6'>
          <Orgao />
        </div>
      </div>
    </div>
  )
}

export default Localizacao
